import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const skucode = [
    { label: 'BANT', value: 'BANT' },
    { label: 'BrandConnect', value: 'BrandConnect' },
    { label: 'HiSync', value: 'HiSync' },
    { label: 'HQLiQ', value: 'HQLiQ' },
    { label: 'SpendSense', value: 'SpendSense' },
    { label: 'SV BCL', value: 'SV BCL' },
    { label: 'SV CDQA', value: 'SV CDQA' },
    { label: 'SVTV', value: 'SVTV' },
    { label: 'TM', value: 'TM' },
];

const SKU = ({ selectedSKU, handleSelectChange }) => {
  const animatedComponents = makeAnimated();

  return (
    <div>
      <Select
        isMulti
        value={selectedSKU}
        onChange={handleSelectChange}
        options={skucode}
        placeholder="Select SKU Code..."
        components={animatedComponents}
        closeMenuOnSelect={false}
      />
    </div>
  );
};

export default SKU;
