import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const endclient = [
  { label: '1E ', value: '1E ' },
{ label: '1Worldsync', value: '1Worldsync' },
{ label: '3 Marketeers', value: '3 Marketeers' },
{ label: '3M', value: '3M' },
{ label: '8X8', value: '8X8' },
{ label: 'Accolade', value: 'Accolade' },
{ label: 'Aci', value: 'Aci' },
{ label: 'Acoustic Global', value: 'Acoustic Global' },
{ label: 'Acquia', value: 'Acquia' },
{ label: 'Acumatica', value: 'Acumatica' },
{ label: 'Adit', value: 'Adit' },
{ label: 'Adobe', value: 'Adobe' },
{ label: 'Adobe Merkle', value: 'Adobe Merkle' },
{ label: 'Advancedmd', value: 'Advancedmd' },
{ label: 'Adyen', value: 'Adyen' },
{ label: 'Ais Consulting', value: 'Ais Consulting' },
{ label: 'Alert Logic', value: 'Alert Logic' },
{ label: 'Alphasense', value: 'Alphasense' },
{ label: 'Alteryx', value: 'Alteryx' },
{ label: 'Alwayson Business Software', value: 'Alwayson Business Software' },
{ label: 'Amazon', value: 'Amazon' },
{ label: 'Amd', value: 'Amd' },
{ label: 'Amwell', value: 'Amwell' },
{ label: 'Anaplan', value: 'Anaplan' },
{ label: 'Appian', value: 'Appian' },
{ label: 'Appviewx', value: 'Appviewx' },
{ label: 'Aps', value: 'Aps' },
{ label: 'Aptean', value: 'Aptean' },
{ label: 'Aqua Security', value: 'Aqua Security' },
{ label: 'Aruba', value: 'Aruba' },
{ label: 'Asana', value: 'Asana' },
{ label: 'Atomic Fi - Neobanks', value: 'Atomic Fi - Neobanks' },
{ label: 'Autodesk', value: 'Autodesk' },
{ label: 'Avantra', value: 'Avantra' },
{ label: 'Avatour', value: 'Avatour' },
{ label: 'Aveva', value: 'Aveva' },
{ label: 'Avnet (Mckinney)', value: 'Avnet (Mckinney)' },
{ label: 'Axians', value: 'Axians' },
{ label: 'B Braun', value: 'B Braun' },
{ label: 'B2Eb0C Splunk', value: 'B2Eb0C Splunk' },
{ label: 'Backbase', value: 'Backbase' },
{ label: 'Bamboohr', value: 'Bamboohr' },
{ label: 'Barracuda', value: 'Barracuda' },
{ label: 'Beamery', value: 'Beamery' },
{ label: 'Bettercloud', value: 'Bettercloud' },
{ label: 'Bigcommerce', value: 'Bigcommerce' },
{ label: 'Bill.Com', value: 'Bill.Com' },
{ label: 'Billtrust', value: 'Billtrust' },
{ label: 'Biovia', value: 'Biovia' },
{ label: 'Bitrise (Nethawk)', value: 'Bitrise (Nethawk)' },
{ label: 'Bizspeed', value: 'Bizspeed' },
{ label: 'Blend', value: 'Blend' },
{ label: 'Bloomberg', value: 'Bloomberg' },
{ label: 'Bloomreach', value: 'Bloomreach' },
{ label: 'Blue Yonder', value: 'Blue Yonder' },
{ label: 'Blueair', value: 'Blueair' },
{ label: 'Blueconic', value: 'Blueconic' },
{ label: 'Bluecore', value: 'Bluecore' },
{ label: 'Bmc', value: 'Bmc' },
{ label: 'Boingo Wireless', value: 'Boingo Wireless' },
{ label: 'Boomi', value: 'Boomi' },
{ label: 'Boost.Ai', value: 'Boost.Ai' },
{ label: 'Bottomline', value: 'Bottomline' },
{ label: 'Box', value: 'Box' },
{ label: 'Branch', value: 'Branch' },
{ label: 'Bridge Partners Insurance', value: 'Bridge Partners Insurance' },
{ label: 'Built In', value: 'Built In' },
{ label: 'Camms', value: 'Camms' },
{ label: 'Canto', value: 'Canto' },
{ label: 'Carbmee', value: 'Carbmee' },
{ label: 'Carrot Fertility', value: 'Carrot Fertility' },
{ label: 'Cdw', value: 'Cdw' },
{ label: 'Ceridian', value: 'Ceridian' },
{ label: 'Channeladvisor', value: 'Channeladvisor' },
{ label: 'Checkmarx', value: 'Checkmarx' },
{ label: 'Checkr-Staffing Firms', value: 'Checkr-Staffing Firms' },
{ label: 'Ciena', value: 'Ciena' },
{ label: 'Circleci', value: 'Circleci' },
{ label: 'Cirrusmd', value: 'Cirrusmd' },
{ label: 'Cisco', value: 'Cisco' },
{ label: 'Citrix', value: 'Citrix' },
{ label: 'City Facilities Management', value: 'City Facilities Management' },
{ label: 'Claroty', value: 'Claroty' },
{ label: 'Clicklease', value: 'Clicklease' },
{ label: 'Cohesity', value: 'Cohesity' },
{ label: 'Colt It Services', value: 'Colt It Services' },
{ label: 'Commvault', value: 'Commvault' },
{ label: 'Comply Advantage', value: 'Comply Advantage' },
{ label: 'Confluent', value: 'Confluent' },
{ label: 'Conga', value: 'Conga' },
{ label: 'Conjura', value: 'Conjura' },
{ label: 'Content Stack', value: 'Content Stack' },
{ label: 'Contentsquare', value: 'Contentsquare' },
{ label: 'Conversica', value: 'Conversica' },
{ label: 'Conviva', value: 'Conviva' },
{ label: 'Coupa Enterprise', value: 'Coupa Enterprise' },
{ label: 'Cprimepsi', value: 'Cprimepsi' },
{ label: 'Crediblemind', value: 'Crediblemind' },
{ label: 'Crossbordersolution', value: 'Crossbordersolution' },
{ label: 'Cygnet', value: 'Cygnet' },
{ label: 'Cymulate', value: 'Cymulate' },
{ label: 'Cypress Defense', value: 'Cypress Defense' },
{ label: 'D4T4 Solutions Plc', value: 'D4T4 Solutions Plc' },
{ label: 'Dassault', value: 'Dassault' },
{ label: 'Databricks', value: 'Databricks' },
{ label: 'Dataiku', value: 'Dataiku' },
{ label: 'Datalist', value: 'Datalist' },
{ label: 'Dataminr', value: 'Dataminr' },
{ label: 'Dbt Labs', value: 'Dbt Labs' },
{ label: 'Deep Sentinel', value: 'Deep Sentinel' },
{ label: 'Dell', value: 'Dell' },
{ label: 'Deltek', value: 'Deltek' },
{ label: 'Demand Media', value: 'Demand Media' },
{ label: 'Demand Science', value: 'Demand Science' },
{ label: 'Denodo', value: 'Denodo' },
{ label: 'Diligent Trial Brief Tmp', value: 'Diligent Trial Brief Tmp' },
{ label: 'Docusign', value: 'Docusign' },
{ label: 'Dp World', value: 'Dp World' },
{ label: 'Dronedeploy', value: 'Dronedeploy' },
{ label: 'Druva', value: 'Druva' },
{ label: 'Earnix', value: 'Earnix' },
{ label: 'Edgio', value: 'Edgio' },
{ label: 'Efilecabinet', value: 'Efilecabinet' },
{ label: 'Egnyte', value: 'Egnyte' },
{ label: 'Eis Group', value: 'Eis Group' },
{ label: 'Elmo', value: 'Elmo' },
{ label: 'Employ Inc', value: 'Employ Inc' },
{ label: 'Employment Hero', value: 'Employment Hero' },
{ label: 'Endava', value: 'Endava' },
{ label: 'Epicor', value: 'Epicor' },
{ label: 'Eptura', value: 'Eptura' },
{ label: 'Equinix', value: 'Equinix' },
{ label: 'Etq', value: 'Etq' },
{ label: 'Everbridge', value: 'Everbridge' },
{ label: 'Everfi', value: 'Everfi' },
{ label: 'Everside Health', value: 'Everside Health' },
{ label: 'Exasol', value: 'Exasol' },
{ label: 'Exelement', value: 'Exelement' },
{ label: 'F5', value: 'F5' },
{ label: 'Fauna', value: 'Fauna' },
{ label: 'Finastra', value: 'Finastra' },
{ label: 'Fis', value: 'Fis' },
{ label: 'Fivetran', value: 'Fivetran' },
{ label: 'Flexential', value: 'Flexential' },
{ label: 'Flexera', value: 'Flexera' },
{ label: 'Forrester', value: 'Forrester' },
{ label: 'Forsta', value: 'Forsta' },
{ label: 'Fortinet', value: 'Fortinet' },
{ label: 'Freeagent', value: 'Freeagent' },
{ label: 'Freshworks', value: 'Freshworks' },
{ label: 'Fujitsu', value: 'Fujitsu' },
{ label: 'Gain.Pro', value: 'Gain.Pro' },
{ label: 'Gartner', value: 'Gartner' },
{ label: 'Ge Additive', value: 'Ge Additive' },
{ label: 'Genesys', value: 'Genesys' },
{ label: 'Glassbox', value: 'Glassbox' },
{ label: 'Globalization Partners Llc', value: 'Globalization Partners Llc' },
{ label: 'Goldman Sachs', value: 'Goldman Sachs' },
{ label: 'Google', value: 'Google' },
{ label: 'Goto', value: 'Goto' },
{ label: 'Graphus', value: 'Graphus' },
{ label: 'Greenfly', value: 'Greenfly' },
{ label: 'Grid Dynamics', value: 'Grid Dynamics' },
{ label: 'Growth Institute', value: 'Growth Institute' },
{ label: 'Gxo', value: 'Gxo' },
{ label: 'Hackerone', value: 'Hackerone' },
{ label: 'Hashicorp', value: 'Hashicorp' },
{ label: 'Hcaptcha', value: 'Hcaptcha' },
{ label: 'Hcl', value: 'Hcl' },
{ label: 'Headspace Health', value: 'Headspace Health' },
{ label: 'Hex Tech', value: 'Hex Tech' },
{ label: 'Hid Global', value: 'Hid Global' },
{ label: 'Honeywell', value: 'Honeywell' },
{ label: 'Hootsuite', value: 'Hootsuite' },
{ label: 'Hp', value: 'Hp' },
{ label: 'Hungerrush', value: 'Hungerrush' },
{ label: 'Hyland', value: 'Hyland' },
{ label: 'Ibm', value: 'Ibm' },
{ label: 'Ibs Software', value: 'Ibs Software' },
{ label: 'Idc', value: 'Idc' },
{ label: 'Illumio', value: 'Illumio' },
{ label: 'Imanage', value: 'Imanage' },
{ label: 'Immuta', value: 'Immuta' },
{ label: 'Incall Systems', value: 'Incall Systems' },
{ label: 'Indeed Enterprise', value: 'Indeed Enterprise' },
{ label: 'Indegene', value: 'Indegene' },
{ label: 'Infobip', value: 'Infobip' },
{ label: 'Infoblox', value: 'Infoblox' },
{ label: 'Informatica', value: 'Informatica' },
{ label: 'Insightsoftware', value: 'Insightsoftware' },
{ label: 'Instride', value: 'Instride' },
{ label: 'Intel', value: 'Intel' },
{ label: 'Internal Results', value: 'Internal Results' },
{ label: 'Intuit', value: 'Intuit' },
{ label: 'Invicti', value: 'Invicti' },
{ label: 'Iplicit', value: 'Iplicit' },
{ label: 'Iris', value: 'Iris' },
{ label: 'Iris Software', value: 'Iris Software' },
{ label: 'Iron Mountain', value: 'Iron Mountain' },
{ label: 'Itesoft', value: 'Itesoft' },
{ label: 'Jaggaer', value: 'Jaggaer' },
{ label: 'Jcurve', value: 'Jcurve' },
{ label: 'Joltdigital', value: 'Joltdigital' },
{ label: 'Just Global', value: 'Just Global' },
{ label: 'Keebo', value: 'Keebo' },
{ label: 'Keka', value: 'Keka' },
{ label: 'Kinaxis', value: 'Kinaxis' },
{ label: 'Kloudgin', value: 'Kloudgin' },
{ label: 'Koddi', value: 'Koddi' },
{ label: 'Kofax Technologies', value: 'Kofax Technologies' },
{ label: 'Kx Systems Singapore', value: 'Kx Systems Singapore' },
{ label: 'Lacework', value: 'Lacework' },
{ label: 'Lamb Weston', value: 'Lamb Weston' },
{ label: 'Lansa', value: 'Lansa' },
{ label: 'Launchdarkly', value: 'Launchdarkly' },
{ label: 'Learnosity', value: 'Learnosity' },
{ label: 'Legwork', value: 'Legwork' },
{ label: 'Lenovo', value: 'Lenovo' },
{ label: 'Lever', value: 'Lever' },
{ label: 'Logiwa', value: 'Logiwa' },
{ label: 'Logmein Teamviewer', value: 'Logmein Teamviewer' },
{ label: 'Lotus', value: 'Lotus' },
{ label: 'Lumenis (Mccann)', value: 'Lumenis (Mccann)' },
{ label: 'Lumos', value: 'Lumos' },
{ label: 'Lyra Health', value: 'Lyra Health' },
{ label: 'Maestroqa', value: 'Maestroqa' },
{ label: 'Magnet Forensics', value: 'Magnet Forensics' },
{ label: 'Mailchimp', value: 'Mailchimp' },
{ label: 'Make', value: 'Make' },
{ label: 'Matterport', value: 'Matterport' },
{ label: 'Mavrck', value: 'Mavrck' },
{ label: 'Mbx', value: 'Mbx' },
{ label: 'Medallia', value: 'Medallia' },
{ label: 'Medely', value: 'Medely' },
{ label: 'Medpro Disposal', value: 'Medpro Disposal' },
{ label: 'Meridianlink', value: 'Meridianlink' },
{ label: 'Meta', value: 'Meta' },
{ label: 'Metrasens', value: 'Metrasens' },
{ label: 'Mimecast', value: 'Mimecast' },
{ label: 'Mirantis', value: 'Mirantis' },
{ label: 'Mirantis Virtualization', value: 'Mirantis Virtualization' },
{ label: 'Mongodb', value: 'Mongodb' },
{ label: 'Monotype', value: 'Monotype' },
{ label: 'Mosaic.Tech', value: 'Mosaic.Tech' },
{ label: 'Motorola', value: 'Motorola' },
{ label: 'Movingimage', value: 'Movingimage' },
{ label: 'Msft', value: 'Msft' },
{ label: 'Mulesoft', value: 'Mulesoft' },
{ label: 'Nanoprecise Sci Corp', value: 'Nanoprecise Sci Corp' },
{ label: 'Nauto', value: 'Nauto' },
{ label: 'Neo4J', value: 'Neo4J' },
{ label: 'Netradyne', value: 'Netradyne' },
{ label: 'Netskope', value: 'Netskope' },
{ label: 'Netsuite', value: 'Netsuite' },
{ label: 'New Relic', value: 'New Relic' },
{ label: 'Newired', value: 'Newired' },
{ label: 'Newrocket', value: 'Newrocket' },
{ label: 'Nextgen Healthcare', value: 'Nextgen Healthcare' },
{ label: 'Ni Merkle', value: 'Ni Merkle' },
{ label: 'Nice', value: 'Nice' },
{ label: 'Nintex', value: 'Nintex' },
{ label: 'Nokia', value: 'Nokia' },
{ label: 'Normalyze', value: 'Normalyze' },
{ label: 'Ntt', value: 'Ntt' },
{ label: 'Nttd Euc', value: 'Nttd Euc' },
{ label: 'Nuance Communications', value: 'Nuance Communications' },
{ label: 'Nudge', value: 'Nudge' },
{ label: 'Nutanix', value: 'Nutanix' },
{ label: 'Okta', value: 'Okta' },
{ label: 'One Identity', value: 'One Identity' },
{ label: 'Onigroup', value: 'Onigroup' },
{ label: 'Ontra', value: 'Ontra' },
{ label: 'Openenvoy', value: 'Openenvoy' },
{ label: 'Opensystems', value: 'Opensystems' },
{ label: 'Opentext', value: 'Opentext' },
{ label: 'Opsramp', value: 'Opsramp' },
{ label: 'Oracle', value: 'Oracle' },
{ label: 'Orca Security', value: 'Orca Security' },
{ label: 'Ortec', value: 'Ortec' },
{ label: 'Outreach', value: 'Outreach' },
{ label: 'Palo Alto', value: 'Palo Alto' },
{ label: 'Panametrics', value: 'Panametrics' },
{ label: 'Panorays', value: 'Panorays' },
{ label: 'Paypal', value: 'Paypal' },
{ label: 'Paytronix', value: 'Paytronix' },
{ label: 'Pearson', value: 'Pearson' },
{ label: 'Pegasystems', value: 'Pegasystems' },
{ label: 'Pendo.Io', value: 'Pendo.Io' },
{ label: 'Pepsico', value: 'Pepsico' },
{ label: 'Perimeter 81', value: 'Perimeter 81' },
{ label: 'Placer', value: 'Placer' },
{ label: 'Planview', value: 'Planview' },
{ label: 'Pliant.Io', value: 'Pliant.Io' },
{ label: 'Podium', value: 'Podium' },
{ label: 'Poly', value: 'Poly' },
{ label: 'Pondurance', value: 'Pondurance' },
{ label: 'Powell Software', value: 'Powell Software' },
{ label: 'Powerobjects Smb', value: 'Powerobjects Smb' },
{ label: 'Precisely', value: 'Precisely' },
{ label: 'Processunity', value: 'Processunity' },
{ label: 'Productiv', value: 'Productiv' },
{ label: 'Proofpoint', value: 'Proofpoint' },
{ label: 'Pros', value: 'Pros' },
{ label: 'Proscia', value: 'Proscia' },
{ label: 'Provenir', value: 'Provenir' },
{ label: 'Pure Storage', value: 'Pure Storage' },
{ label: 'Pwc', value: 'Pwc' },
{ label: 'Pythian', value: 'Pythian' },
{ label: 'Qlik', value: 'Qlik' },
{ label: 'Quantilope', value: 'Quantilope' },
{ label: 'Quantum Metric', value: 'Quantum Metric' },
{ label: 'Rackspace', value: 'Rackspace' },
{ label: 'Radware', value: 'Radware' },
{ label: 'Razorpod', value: 'Razorpod' },
{ label: 'Rc Greenfield', value: 'Rc Greenfield' },
{ label: 'Red Hat', value: 'Red Hat' },
{ label: 'Red River', value: 'Red River' },
{ label: 'Regalix', value: 'Regalix' },
{ label: 'Relex', value: 'Relex' },
{ label: 'Reliaquest', value: 'Reliaquest' },
{ label: 'Replicant', value: 'Replicant' },
{ label: 'Revolutionparts', value: 'Revolutionparts' },
{ label: 'Rezilion', value: 'Rezilion' },
{ label: 'Rh', value: 'Rh' },
{ label: 'Ricoh', value: 'Ricoh' },
{ label: 'Rimini Street', value: 'Rimini Street' },
{ label: 'Riskified Ltd.', value: 'Riskified Ltd.' },
{ label: 'Rollworks', value: 'Rollworks' },
{ label: 'Rubrik', value: 'Rubrik' },
{ label: 'Rxt', value: 'Rxt' },
{ label: 'S&P Global', value: 'S&P Global' },
{ label: 'Safe Software', value: 'Safe Software' },
{ label: 'Sage', value: 'Sage' },
{ label: 'Sai Digital', value: 'Sai Digital' },
{ label: 'Sailpoint', value: 'Sailpoint' },
{ label: 'Salesforce', value: 'Salesforce' },
{ label: 'Sap', value: 'Sap' },
{ label: 'Sas Institute', value: 'Sas Institute' },
{ label: 'Sas Retail', value: 'Sas Retail' },
{ label: 'Sbg Funding', value: 'Sbg Funding' },
{ label: 'Schneider Electric Solar', value: 'Schneider Electric Solar' },
{ label: 'Sedex', value: 'Sedex' },
{ label: 'Sendoso', value: 'Sendoso' },
{ label: 'Sensor Tower', value: 'Sensor Tower' },
{ label: 'Sentinelone Cloud', value: 'Sentinelone Cloud' },
{ label: 'Servicenow', value: 'Servicenow' },
{ label: 'Ses Satellites', value: 'Ses Satellites' },
{ label: 'Shopify', value: 'Shopify' },
{ label: 'Siemens', value: 'Siemens' },
{ label: 'Sirionlabs', value: 'Sirionlabs' },
{ label: 'Site Improve', value: 'Site Improve' },
{ label: 'Skybox Security', value: 'Skybox Security' },
{ label: 'Slack', value: 'Slack' },
{ label: 'Smart Wireless', value: 'Smart Wireless' },
{ label: 'Smartsheet', value: 'Smartsheet' },
{ label: 'Smg', value: 'Smg' },
{ label: 'Snapfulfil', value: 'Snapfulfil' },
{ label: 'Socialbakers', value: 'Socialbakers' },
{ label: 'Software Ag', value: 'Software Ag' },
{ label: 'Sophos', value: 'Sophos' },
{ label: 'Spectrum', value: 'Spectrum' },
{ label: 'Spectrum Enterprise', value: 'Spectrum Enterprise' },
{ label: 'Spendesk', value: 'Spendesk' },
{ label: 'Spiff', value: 'Spiff' },
{ label: 'Splunk', value: 'Splunk' },
{ label: 'Sprinklr', value: 'Sprinklr' },
{ label: 'Starburst Data', value: 'Starburst Data' },
{ label: 'Storyblok', value: 'Storyblok' },
{ label: 'Streamsets', value: 'Streamsets' },
{ label: 'Supermicro', value: 'Supermicro' },
{ label: 'Swrve', value: 'Swrve' },
{ label: 'Symphony', value: 'Symphony' },
{ label: 'Tableau', value: 'Tableau' },
{ label: 'Tagetik', value: 'Tagetik' },
{ label: 'Tas', value: 'Tas' },
{ label: 'Tcs', value: 'Tcs' },
{ label: 'Tealium', value: 'Tealium' },
{ label: 'Teleport', value: 'Teleport' },
{ label: 'Teradata', value: 'Teradata' },
{ label: 'Texere', value: 'Texere' },
{ label: 'The Access Group', value: 'The Access Group' },
{ label: 'Thermofisher', value: 'Thermofisher' },
{ label: 'Thomas Applied Material Solutions', value: 'Thomas Applied Material Solutions' },
{ label: 'Thomas Econo', value: 'Thomas Econo' },
{ label: 'Thoughtworks', value: 'Thoughtworks' },
{ label: 'Threat Defence', value: 'Threat Defence' },
{ label: 'Thryv', value: 'Thryv' },
{ label: 'Tierpoint', value: 'Tierpoint' },
{ label: 'Timextender', value: 'Timextender' },
{ label: 'Tinuiti', value: 'Tinuiti' },
{ label: 'Tmm', value: 'Tmm' },
{ label: 'T-Mobile', value: 'T-Mobile' },
{ label: 'Trademo', value: 'Trademo' },
{ label: 'Trilio', value: 'Trilio' },
{ label: 'T-Systems', value: 'T-Systems' },
{ label: 'Ul', value: 'Ul' },
{ label: 'Unit4', value: 'Unit4' },
{ label: 'Uob', value: 'Uob' },
{ label: 'Upcloud', value: 'Upcloud' },
{ label: 'Urban Sports Club', value: 'Urban Sports Club' },
{ label: 'Validity', value: 'Validity' },
{ label: 'Vantage', value: 'Vantage' },
{ label: 'Veeam', value: 'Veeam' },
{ label: 'Veeva Systems', value: 'Veeva Systems' },
{ label: 'Vendavo', value: 'Vendavo' },
{ label: 'Vercel', value: 'Vercel' },
{ label: 'Veriff', value: 'Veriff' },
{ label: 'Veritas Technologies', value: 'Veritas Technologies' },
{ label: 'Vetasi', value: 'Vetasi' },
{ label: 'Virtru International', value: 'Virtru International' },
{ label: 'Vmware', value: 'Vmware' },
{ label: 'Wei', value: 'Wei' },
{ label: 'Wekaio', value: 'Wekaio' },
{ label: 'Whatsapp', value: 'Whatsapp' },
{ label: 'Winmo', value: 'Winmo' },
{ label: 'Winshuttle', value: 'Winshuttle' },
{ label: 'Wolters Kluwer', value: 'Wolters Kluwer' },
{ label: 'Workday', value: 'Workday' },
{ label: 'Workjam', value: 'Workjam' },
{ label: 'Wso2', value: 'Wso2' },
{ label: 'Yalo', value: 'Yalo' },
{ label: 'Zebra', value: 'Zebra' },
{ label: 'Zebra Technologies', value: 'Zebra Technologies' },
{ label: 'Zendesk', value: 'Zendesk' },
{ label: 'Zeta Tech', value: 'Zeta Tech' },
{ label: 'Zs Associates', value: 'Zs Associates' },
{ label: 'Zsa', value: 'Zsa' },
];

const Client = ({ selectedClient, handleSelectChange }) => {
  const animatedComponents = makeAnimated();

  return (
    <div>
      <Select
        isMulti
        value={selectedClient}
        onChange={handleSelectChange}
        options={endclient}
        placeholder="Select End Client..."
        components={animatedComponents}
        closeMenuOnSelect={false}
      />
    </div>
  );
};

export default Client;
