import { useState } from 'react';
import * as XLSX from 'xlsx';

const useUploadMD5Suppression = (filteredData, setFilteredData) => {
  const [file, setFile] = useState(null);
  const [md5Data, setMd5Data] = useState([]);
  const [applied, setApplied] = useState(false);

  const handleFileChange = (file) => {
    setFile(file);
    const reader = new FileReader();
    reader.onload = () => {
      const workbook = XLSX.read(reader.result, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      
      // Extract MD5 values from the 1st, 2nd, and 3rd columns, skipping the header row
      const md5Data = data.slice(1).flatMap(row => {
        // Assuming MD5 values are in the 1st (index 0), 2nd (index 1), and 3rd (index 2) columns
        return [row[0], row[1], row[2]].filter(md5 => md5); // Filter out any empty values
      });
      
      setMd5Data(md5Data); // Set the MD5 data in state
    };
    reader.readAsArrayBuffer(file);
  };

  const applyMD5Suppression = (filteredData) => {
    return filteredData.filter((item) => {
      return !md5Data.includes(item.MD5); // Suppress based on MD5 match
    });
  };

  return { file, handleFileChange, applyMD5Suppression, applied, md5Data };
};

export default useUploadMD5Suppression;