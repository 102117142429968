import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const jobFunctions = [
  { label: 'Admin', value: 'Admin' },
{ label: 'Consulting', value: 'Consulting' },
{ label: 'Content Production', value: 'Content Production' },
{ label: 'Customer Experience', value: 'Customer Experience' },
{ label: 'Design', value: 'Design' },
{ label: 'Education', value: 'Education' },
{ label: 'Engineering (Non Software)', value: 'Engineering (Non Software)' },
{ label: 'Finance', value: 'Finance' },
{ label: 'Food Services', value: 'Food Services' },
{ label: 'General Management', value: 'General Management' },
{ label: 'Government And Education', value: 'Government And Education' },
{ label: 'Healthcare', value: 'Healthcare' },
{ label: 'Human Resources', value: 'Human Resources' },
{ label: 'Individual Contributor', value: 'Individual Contributor' },
{ label: 'Information Technology', value: 'Information Technology' },
{ label: 'Legal', value: 'Legal' },
{ label: 'Logistics', value: 'Logistics' },
{ label: 'Management', value: 'Management' },
{ label: 'Manufacturing', value: 'Manufacturing' },
{ label: 'Marketing', value: 'Marketing' },
{ label: 'Operations', value: 'Operations' },
{ label: 'Other Functions', value: 'Other Functions' },
{ label: 'Procurement', value: 'Procurement' },
{ label: 'Product Management, Research, & Innovation', value: 'Product Management, Research, & Innovation' },
{ label: 'Property Management', value: 'Property Management' },
{ label: 'Protective Services', value: 'Protective Services' },
{ label: 'Public Relations', value: 'Public Relations' },
{ label: 'Sales', value: 'Sales' },
{ label: 'Software Development', value: 'Software Development' },
{ label: 'Support', value: 'Support' },
];

const JobFunction = ({ selectedJobFunction, handleSelectChange }) => {
  const animatedComponents = makeAnimated();

  return (
    <div>
      <Select
        isMulti
        value={selectedJobFunction}
        onChange={handleSelectChange}
        options={jobFunctions}
        placeholder="Select Job Function..."
        components={animatedComponents}
        closeMenuOnSelect={false}
      />
    </div>
  );
};

export default JobFunction;


// import React from 'react';
// import Select from 'react-select';
// import makeAnimated from 'react-select/animated';

// const JobFunction = ({ data, selectedJobFunction, handleSelectChange }) => {
//   const jobFunction = [...new Set(data.map((item) => item.JobFunction))].map((jobFunc) => ({
//     value: jobFunc,
//     label: jobFunc,
//   }));

//   // Sort the city names in alphabetical order
//   jobFunction.sort((a, b) => a.label.localeCompare(b.label));  

//   const animatedComponents = makeAnimated();  

//   return (
//     <div>
//       <Select
//         isMulti
//         value={selectedJobFunction}
//         onChange={handleSelectChange}
//         options={jobFunction}
//         placeholder="Select Job Function..."
//         components={animatedComponents}
//         closeMenuOnSelect={false} // Add this prop
//       />
//     </div>
//   );
// };

// export default JobFunction;