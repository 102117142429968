import React, { useState, useEffect } from 'react';
import moment from 'moment';

const DateRangeFilter = ({ data, handleSelectChange, resetFilters }) => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  useEffect(() => {
    if (resetFilters) {
      handleResetFilters();
    }
  }, [resetFilters]);

  const handleFromDateChange = (e) => {
    const selectedFromDate = e.target.value;
    setFromDate(selectedFromDate);
    handleSelectChange(selectedFromDate ? moment(selectedFromDate).format('YYYY-MM-DD') : '', toDate); // Update the parent with the new date range
  };

  const handleToDateChange = (e) => {
    const selectedToDate = e.target.value;
    setToDate(selectedToDate);
    handleSelectChange(fromDate, selectedToDate ? moment(selectedToDate).format('YYYY-MM-DD') : ''); // Update the parent with the new date range
  };

  const handleResetFilters = () => {
    setFromDate('');
    setToDate('');
    handleSelectChange('', ''); // Reset date range in parent
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <label style={{ marginRight: 10, color: 'white' }}><b>From :</b></label>
      <input type="date" value={fromDate} onChange={handleFromDateChange} style={{ marginRight: 20 }} />
      <label style={{ marginRight: 10, color: 'white' }}><b>To :</b></label>
      <input type="date" value={toDate} onChange={handleToDateChange} />
    </div>
  );
};

export default DateRangeFilter;